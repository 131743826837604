/* eslint-disable */

import Text from "components/general/Text";
import moment from "moment";
import styles from "./styles.module.scss";
import { StateType } from "./types";
import Button from "../Button";

export default function CalendarRow({
  dataItem,
  onClickHandler,
  showActionButton = true,
  isPublicHoliday,
}: any) {
  const date = moment(dataItem.date).format("ddd DD/ MMM");
  const hours = dataItem.start_time
    ? moment(dataItem.start_time, "hh:mm:ss").format("hh:mm A")
    : "N/A";
  const isFutureDate = moment(dataItem.date).isAfter(moment());
  console.log(dataItem, "Insideeeeeeeeeeeeeeeeeeee");

  const StateTypeMap = new Map<string, string>([
    [StateType.success, StateType.success],
    [StateType.absent, StateType.absent],
    [StateType.permission, StateType.permission],
    [StateType.conflict, StateType.conflict],
    [StateType.off, StateType.off],
    [StateType.publicHoliday, StateType.publicHoliday],
  ]);

  const stateClassName =
    StateTypeMap.get(
      isPublicHoliday?.isHoliday ? "publicHoliday" : dataItem?.state
    ) || "";
  const borderStateClassName =
    `${`${StateTypeMap.get(
      isPublicHoliday?.isHoliday ? "publicHoliday" : dataItem?.state
    )}Border`}` || "";

  const IsFutureContent = () => (
    <div className={styles.content}>
      <Text
        style={{ fontSize: "16px", textTransform: "capitalize" }}
        className="me-1"
      >
        {date} :{" "}
        {isPublicHoliday?.isHoliday
          ? `${isPublicHoliday?.name},`
          : `${dataItem?.fixed_shift_name},`}
      </Text>

      <Text style={{ fontSize: "16px" }}>
        {dataItem.shiftType && (
          <span className={styles.successTxt}>
            {`${dataItem.shiftType} Shift`}{" "}
          </span>
        )}
      </Text>
      <Text style={{ fontSize: "16px" }}>
        {`, ${dataItem.working_hour} hours` ?? "N/A"}
      </Text>
    </div>
  );

  return (
    <div className={`${styles.container} ${styles[stateClassName]}`}>
      <div className="d-flex align-items-center">
        <div
          className={`${styles.startBorder} ${styles[borderStateClassName]}`}
        />
        {isFutureDate ? (
          <IsFutureContent />
        ) : (
          <>
            {StateTypeMap.get(dataItem?.state) === StateType.success ? (
              <div className={styles.content}>
                <Text style={{ fontSize: "16px" }} className="me-1">
                  {date} :
                </Text>

                <Text style={{ fontSize: "16px" }}>
                  {dataItem.shiftType && (
                    <span className={styles.successTxt}>
                      {`${dataItem.shiftType} Shift`}{" "}
                    </span>
                  )}
                  Start:
                  {hours}, {`${dataItem.working_hour} hours` ?? "N/A"}
                </Text>
                <Text style={{ fontSize: "16px" }}>
                  {dataItem.shiftType && <span>{`, checkin time: `} </span>}
                  {String(moment(dataItem?.checkin?.checkin_time).format("LT"))}
                </Text>
                <Text style={{ fontSize: "16px" }}>
                  {dataItem.shiftType && <span>{`, checkout time: `} </span>}
                  {String(
                    moment(dataItem?.checkin?.checkout_time).format("LT")
                  )}
                </Text>
              </div>
            ) : (
              <div className={styles.content}>
                <Text
                  style={{ fontSize: "16px", textTransform: "capitalize" }}
                  className="me-1"
                >
                  {date} :{" "}
                  {isPublicHoliday?.isHoliday
                    ? `${isPublicHoliday?.name}`
                    : StateTypeMap.get(dataItem?.state)}
                </Text>
              </div>
            )}
          </>
        )}

        {/* {StateTypeMap.get(dataItem?.state) === StateType.success ? (
          <div className={styles.content}>
            <Text style={{ fontSize: "16px" }} className="me-1">
              {date} :
            </Text>

            <Text style={{ fontSize: "16px" }}>
              {dataItem.shiftType && (
                <span className={styles.successTxt}>
                  {`${dataItem.shiftType} Shift`}{" "}
                </span>
              )}
              Start:
              {hours}, {`${dataItem.working_hour} hours` ?? "N/A"}
            </Text>
            <Text style={{ fontSize: "16px" }}>
              {dataItem.shiftType && <span>{`, checkin time: `} </span>}
              {String(moment(dataItem?.checkin?.checkin_time).format("LT"))}
            </Text>
            <Text style={{ fontSize: "16px" }}>
              {dataItem.shiftType && <span>{`, checkout time: `} </span>}
              {String(moment(dataItem?.checkin?.checkout_time).format("LT"))}
            </Text>
          </div>
        ) : (
          <div className={styles.content}>
            <Text
              style={{ fontSize: "16px", textTransform: "capitalize" }}
              className="me-1"
            >
              {date} :{" "}
              {isPublicHoliday?.isHoliday
                ? `${isPublicHoliday?.name}`
                : StateTypeMap.get(dataItem?.state)}
            </Text>
          </div>
        )} */}
      </div>

      {showActionButton && (
        <Button
          btnClassName={styles.button}
          onClick={() => onClickHandler?.(dataItem?.id)}
        >
          ...
        </Button>
      )}
    </div>
  );
}
